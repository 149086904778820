<template>
<div>
  <div class="my-alert alert-warning">
    <span class="my-alert-icon"><i class="ivu-icon ivu-icon-md-alert" style="font-size: 16px; color: rgb(255, 153, 0);"></i></span>
    <div class="my-alert-info">
      <p style="font-weight: bold;">温馨提示</p>
      <ul class="item-ul" style="padding-top: 4px;">
        <li><p>默认设备数量：指客户默认最大绑定设备数量，超出数量范围则需购买流量套餐。</p></li>
        <li><p>设备流量套餐：指客户每增加一台设备的金额。</p></li>
      </ul>
    </div>
  </div>
  <div class="ser-form">
    <Form :model="formData" :label-width="120">
      <FormItem label="默认设备数量">
        <InputNumber
            style="width: 140px;"
            :max="9999999"
            :min="0"
            v-model="formData.sys_device_default"
            :formatter="value => `${value} 台`"
            :parser="value => value.replace('台', '')"></InputNumber>
      </FormItem>
      <FormItem label="设备流量套餐">
        <InputNumber
            style="width: 140px;"
            :max="9999999"
            :min="0.01"
            :precision="2"
            v-model="formData.sys_device_package"
            :formatter="value => `${value} 元/台`"
            :parser="value => value.replace('元/台', '')"></InputNumber>
      </FormItem>
      <FormItem>
        <Button type="primary" @click="SetSysConfig">保存设置</Button>
      </FormItem>
    </Form>
  </div>
</div>
</template>

<script>
export default {
  name: "device_package",
  data(){
    return{
      formData:{
        sys_device_package:2,
        sys_device_default:10
      }
    }
  },
  created() {
    this.init()
  },
  methods:{
    init(){
      var that = this
      this.requestApi('/adm/get_system_config', {data:this.formData}).then((res)=>{
        if(res.status) {
          that.formData = res.data
        }
      })
    },
    SetSysConfig(){
      var that = this
      this.requestApi('/adm/set_system_config', {data:this.formData}).then((res)=>{
        if(res.status) {
          that.alertSucc(res.msg)
          that.init()
        }else{
          that.alertErr(res.msg)
        }
      })
    }
  }
}
</script>

<style scoped>
.my-alert{
  font-size: 12px;
  line-height: inherit;
  padding: 14px 20px;
  vertical-align: middle;
  color: #002da0;
  border: 1px solid #d5e7ff;
  border-radius: 0;
  background: #d5e7ff;
  position: relative;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start
}
.alert-warning{
  color: #a02800;
  background-color: #ffe8d5;
  border-color: #ffe8d5;
}
.my-alert-icon{
  font-size: 0;
  flex: 0 0 16px;
  margin-right: 16px;
  margin-top: 1px;
}
.my-alert-info{
  flex: 1;
  font-size: 12px;
  line-height: inherit;
  color: inherit
}

.item-ul{
  color: inherit;
  padding: 10px 0 0;
  list-style: none
}

.item-ul>li{
  position: relative;
  padding-left: 10px;
  line-height: 1.5;
  margin-bottom: 8px;
  box-sizing: border-box
}

.item-ul li:last-of-type {
  margin-bottom: 0
}
</style>